import { GETPRIORITY, GETPROJECT, GETUSER, GETTEAMUSER, LOGIN, LOGOUT, UPDATE_LAST_UPDATE_TIME } from '../constant';

const initialState = {
  userDetails: {},
  userList: [],
  projectList: [],
  lastUpdateTime: '',
  priorityList: [],
  teamUserList : []

};

// eslint-disable-next-line default-param-last
const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, userDetails: action.data };
    case LOGOUT:
      return { ...state, userDetails: {} };
    case GETPROJECT:
      return { ...state, projectList: action.data };
    case GETPRIORITY:
      return { ...state, priorityList: action.data };
    case GETTEAMUSER:
      return { ...state, teamUserList: action.data };
    case GETUSER:
      return { ...state, userList: action.data };
    case UPDATE_LAST_UPDATE_TIME:
      return { ...state, lastUpdateTime: action.data };
    default:
      return state;
  }
};

export default loginReducer;
