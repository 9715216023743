import { AnimatePresence, m } from 'framer-motion';
import * as React from 'react';
// form
// @mui
import {
  Backdrop,
  Box,
  Collapse,
  DialogContent,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Avatar,
  Typography,
  Skeleton,
} from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useTheme } from '@emotion/react';
import { alpha, styled } from '@mui/material/styles';
// _mock
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-unresolved
import moment from 'moment';
import PropTypes from 'prop-types';
import Iconify from 'src/components/Iconify';
import AxiosAll from 'src/services/axiosAll';
import cssStyles from 'src/utils/cssStyles';
import palette from 'src/theme/palette';
import { IconButtonAnimate } from '../animate';
import AvailabilityFilter from '../AvailabilityFilter';
import AvailabilityCardSkeleton from '../AvailabilityCardSkeleton';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
  top: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  position: 'fixed',
  // overflowY: 'áuto',
  // maxHeight: '200',
  // width: window.innerWidth <= 450 ? '55vw' : '28vw',
  flexDirection: 'column',
  margin: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  zIndex: theme.zIndex.drawer + 3,
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  overflow: 'inherit',
  height: '90vh',
  color: theme.palette.text.primary,
  boxShadow: `-24px 12px 32px -4px ${alpha(
    theme.palette.mode === 'light' ? theme.palette.grey[500] : theme.palette.common.black,
    0.16
  )}`,
}));

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const UserCard = ({ user }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let availabilityStatus = 'Not Available';

  if (user?.availability_status_id === 2 || user?.availability_status_id === 6) {
    availabilityStatus = 'Available';
  } else if (user?.title === "break") {
    availabilityStatus = 'On Lunch Break';
  } else if (user?.title) {
    availabilityStatus = user?.title;
  }

  // calculating end time 
  const calculateEndTime = (createdAt, durationInMinutes) => {
    // Add duration (in minutes) to created_at time
    const endTime = moment(createdAt).add(durationInMinutes, 'minutes');

    // Format the result as 'h:mm a'
    return endTime.format('h:mm a');
  };

  return (
    <Box
      className="availability_text"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 2.5,
        marginTop: 2,
        borderRadius: 1,
        background: 'palette.background.default',
        boxShadow: '0 1px 1px 0 rgba(66, 66, 66, 0.08), 0 1px 3px 1px rgba(66, 66, 66, 0.16)',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'palette.background.grey[500]',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Avatar
            sx={{ width: 45, height: 45 }}
            src={user?.user_profile?.profile_picture}
            alt={`${user?.user_profile?.first_name} ${user?.user_profile?.last_name}`}
          />
          <div>
            <Typography sx={{ marginLeft: 1.5 }} variant="subtitle1" color="text.secondary">
              {user?.user_profile?.first_name} {user?.user_profile?.last_name}
            </Typography>
            <Typography sx={{ marginLeft: 1.5 }} variant="body2" color="text.secondary">
              {availabilityStatus}
            </Typography>
          </div>

          <Iconify
            sx={{ marginLeft: 1, mt: 0.5 }}
            color={user?.availability_status_id === 2 || user?.availability_status_id === 6 ? '#00a810' : '#ff0000'}
            icon={'material-symbols:circle'}
            width={15}
            height={15}
          />
        </Box>
        <Typography
          variant="body2"

        >


          {user?.created_at && (() => {
            if (user?.availability_status_id === 4 && user?.title === 'break') {
              return `from: ${moment(user?.created_at).format('h:mm a')}`;
            }
            switch (user?.availability_status_id) {
              case 3:
                return `${moment(user?.created_at).format('h:mm a')}`;
              case 7:
              case 8:
              case 9:
              case 4: // Show end time if availability_status_id is 4 and title is not "break"
                return `Available at: ${calculateEndTime(user?.created_at, user?.duration)}`;
              default:
                return '';
            }
          })()}

        </Typography>
        {user?.comment && (
          <IconButton onClick={handleExpandClick}>
            <Iconify icon={expanded ? 'eva:arrow-up-fill' : 'eva:arrow-down-fill'} width={20} height={20} />
          </IconButton>
        )}
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
        <Box
          sx={{
            // display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 2,
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: '400' }}>
            {user?.comment}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

function CustomTabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      // hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ p: 0 }}>
        <Typography>{children}</Typography>
      </Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

AvailabilitySidebar.propTypes = {};

UserCard.propTypes = {
  user: PropTypes.object,
};

export default function AvailabilitySidebar() {
  const setting = localStorage.getItem('settings');
  const mode = JSON.parse(setting);
  const theme = useTheme();
  const { userDetails } = useSelector((state) => state?.loginReducer);
  const { rcount } = useSelector((state) => state.reviewReducer);

  const [open, setOpen] = useState(false);
  const { token } = userDetails;
  const [searchText, setSearchText] = useState('');
  const [search, setSearch] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [projectData, setProjectData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [availabilityList, setAvailabilityList] = useState([]);
  const [availabilityFilter, setAvailabilityFilter] = useState('');
  const [indexValue, setIndexValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setIndexValue(newValue);
    if (newValue === 0) {
      setAvailabilityFilter('');
    } else if (newValue === 1) {
      setAvailabilityFilter('available');
    } else if (newValue === 2) {
      setAvailabilityFilter('unavailable');
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getAvailableUsers = async (search, status) => {
    const query = {
      availableStatus: status || '',
      search: search || '',
    };

    setLoading(true);
    const res = await AxiosAll(
      'get',
      `/availability/users?availability_status=${query?.availableStatus}&query=${query?.search}`,
      {},
      token
    );
    if (res?.status === 200) {
      const newData = res?.data?.data;
      setAvailabilityList(newData);
    }
    setLoading(false);
  };

  useEffect(() => {
    // debounce search
    const timeOutId = setTimeout(() => {
      setSearch(searchText);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [searchText]);

  useEffect(() => {
    if (open) {
      getAvailableUsers(search, availabilityFilter);
    }
  }, [open, search, availabilityFilter]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <IconButtonAnimate
          color={open ? 'primary' : 'default'}
          onClick={() => setOpen(true)}
          sx={{ width: 40, height: 40, backgroundColor: 'rgba(99, 115, 129, 0.08)' }}
        >
          <Iconify icon="fa6-solid:user" />
          {/* <Iconify icon="solar:users-group-rounded-bold-duotone" width={40} height={40} /> */}
        </IconButtonAnimate>
        <Iconify
          icon="material-symbols-light:circle"
          style={{
            position: 'absolute',
            width: '15px',
            height: '15px',
            right: '-5px',
            color:
              rcount?.availability_status?.availability_status_id === 6 ||
                rcount?.availability_status?.availability_status_id === 2
                ? '#00a810'
                : '#ff0000',
          }}
        />
      </div>
      {open && (
        <div>
          <Backdrop open={open} sx={{ background: 'transparent', zIndex: (theme) => theme.zIndex.drawer + 1 }} />
          <AnimatePresence>
            <RootStyle
              className="sidebar-inputfield user_availability"
              sx={{
                width: '500px',
                '@media screen and (max-width: 600px)': {
                  width: '100%',
                  height: '100vh',
                },
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ py: 0.5, pr: 1, pl: 2.5 }}
              >
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }} color="text.secondary">
                  Users Availability
                </Typography>

                <IconButton onClick={handleClose}>
                  <Iconify icon={'eva:close-fill'} width={20} height={20} />
                </IconButton>
              </Stack>
              <Stack spacing={3} sx={{ p: 3, pb: 0 }} direction="row" alignItems="center">
                <TextField
                  fullWidth
                  value={searchText}
                  onChange={(event) => setSearchText(event.target.value)}
                  placeholder={`Search ${searchText ?? `user`}...`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <DialogContent>
                <Box sx={{ width: '100%' }} className="availability_tab">
                  <Box>
                    <Tabs
                      value={indexValue}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="icon tabs example"
                    >
                      {/* <Tab label="All" {...a11yProps(0)} />
                      <Tab label="Available" {...a11yProps(1)} />
                      <Tab label="Unavailable" {...a11yProps(2)} /> */}
                      <Tab
                        label="All"
                        {...a11yProps(0)}
                        className={mode?.themeMode === 'dark' ? 'dark_mode_text' : 'light_mode_text'}
                      />
                      <Tab
                        label="Available"
                        {...a11yProps(1)}
                        className={mode?.themeMode === 'dark' ? 'dark_mode_text' : 'light_mode_text'}
                      />
                      <Tab
                        label="Unavailable"
                        {...a11yProps(2)}
                        className={mode?.themeMode === 'dark' ? 'dark_mode_text' : 'light_mode_text'}
                      />
                    </Tabs>
                  </Box>
                  <CustomTabPanel index={indexValue}>
                    <Stack spacing={3}>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {loading ? (
                        <AvailabilityCardSkeleton />
                      ) : availabilityList?.length > 0 ? (
                        availabilityList?.map((user) => <UserCard key={user?.id} user={user} />)
                      ) : (
                        <Typography>No user found</Typography>
                      )}
                    </Stack>
                  </CustomTabPanel>
                </Box>
              </DialogContent>
            </RootStyle>
          </AnimatePresence>
        </div>
      )}
    </>
  );
}
