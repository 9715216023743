import { GETPRIORITY, GETPROJECT, GETUSER, GETTEAMUSER, LOGIN, LOGOUT, UPDATE_LAST_UPDATE_TIME } from '../constant';
// action CRUD for Login data

export const addLogin = (logindata) => {
  return { type: LOGIN, data: logindata };
};

export const deleteLogin = () => {
  return { type: LOGOUT };
};

export const getUser = (userList) => {
  return { type: GETUSER, data: userList };
};

export const getProject = (projectList) => {
  return { type: GETPROJECT, data: projectList };
};

export const getpriority = (priorityList) => {
  return { type: GETPRIORITY, data: priorityList };
};

export const getTeamUser = (teamUserList) => {
  return { type: GETTEAMUSER, data: teamUserList };
};

export const updateLastUpdateTime = (updateTime) => {
  return { type: UPDATE_LAST_UPDATE_TIME, data: updateTime };
};

