// import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import { Divider, InputAdornment, Stack } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';
import AxiosAll from 'src/services/axiosAll';
import { useSnackbar } from 'notistack';
import { setCount } from 'src/redux/actions/reviewCount';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BreakComponent = ({ breakstart, setBreak }) => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const { rcount } = useSelector((state) => state.reviewReducer);
  const [isBreak, setIsBreak] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [customTime, setCustomTime] = useState('00:00');
  const [defaultBreak, setDefaultbreak] = useState('');
  const [countdown, setCountdown] = React.useState(300); // 5 minutes countdown
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState('');
  const [active, setActive] = useState(false);
  const [startTime, setStartTime] = useState();
  const [error, setError] = useState('');
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleBreakClick = () => {
    setIsBreak(true);
    setOpen(true);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setBreak(false);
    setCustomTime('00:00');
    setDefaultbreak('');
  };
  const handleCustomTimeChange = (event) => {
    setActive(false);
    setDefaultbreak('');
    setMessage('');
    const value = event.target.value;
    if (value) {
      const isValidTime = validateTimeFormat(value);
      if (isValidTime) {
        setCustomTime(value);
        setError('');
      } else {
        setCustomTime('00:00');
        setError('Invalid time format. Please use HH:MM in 12-hour format.');
      }

      // const isValidFormat = validateTimeFormat(value);
    } else {
      setError('');
      setCustomTime('00:00');
    }
  };

  const validateTimeFormat = (time) => {
    const regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/; // HH:MM format
    return regex.test(time);
  };

  const handleTimeChange = (event) => {
    setCountdown('');
    if (event === 0) {
      setActive(true);
      setDefaultbreak(event);
      setMessage('Taking Lunch Break !');
    } else if (event === 5) {
      setActive(true);
      setDefaultbreak(event);
      setMessage(`5 Minute Break !`);
    } else if (event === 10) {
      setActive(true);
      setDefaultbreak(event);
      setMessage(`10 Minute Break !`);
    } else if (event === 15) {
      setActive(true);
      setDefaultbreak(event);
      setMessage(`15 Minute Break !`);
    } else {
      const value = event;
      setActive(true);
      setDefaultbreak(value); // Parse the value as an integer
      setMessage('');
    }
  };

  const handleBackButtonClick = () => {
    setIsBreak(false);
    setBreak(false);
  };

  useEffect(() => {
    if (rcount?.availability_status?.availability_status_id === 4) {
      setIsBreak(true);
      setCustomTime(rcount?.availability_status?.duration);
      const dateObject = new Date(rcount?.availability_status?.created_at);
      setStartTime(dateObject);
    }
  }, [rcount]);

  const handleBreak = async () => {
    if (customTime !== '00:00' || defaultBreak !== '') {
      setLoading(true);
      const [hours, minutes] = customTime?.split(':').map(Number);
      const totalMinutes = hours * 60 + minutes;
      try {
        const res = await AxiosAll(
          'post',
          `/availability`,
          {
            availability_status_id: 4,
            duration: active ? defaultBreak : totalMinutes,
            comment: message,
          },
          userDetails?.token
        );
        const data = res.data.data.data;
        // setActivityList(data);
        if (res?.status === 201) {
          setIsBreak(true);
          setCustomTime(res?.data?.data?.duration);
          const dateObject = new Date(res?.data?.data?.created_at);
          setStartTime(dateObject);
          enqueueSnackbar(`${res?.data?.message}`, { variant: 'success' });
        } else {
          setIsBreak(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleBackTOWork = async () => {
    setLoading(true);
    try {
      const res = await AxiosAll(
        'post',
        `/availability`,
        {
          availability_status_id: 6,
        },
        userDetails?.token
      );
      const data = res.data.data.data;
      // setActivityList(data);
      getCounts();
      if (res?.status === 201) {
        setIsBreak(false);
        handleBackButtonClick();
        setCustomTime('00:00');
        setDefaultbreak('');
        setActive(false);
        setBreak(false);
        enqueueSnackbar(`${res?.data?.message}`, { variant: 'success' });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCounts = async () => {
    const response = await AxiosAll('get', '/counts', {}, userDetails?.token);
    const { data } = response;
    dispatch(setCount(data));
  };

  return (
    <div>
      {/* <Button variant="contained" onClick={handleClickOpen}>
        Break
      </Button> */}
      <BootstrapDialog
        // onClose={handleClose}
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={breakstart}
      >
        {' '}
        {isBreak ? (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Break Time
            </DialogTitle>
            <Divider sx={{ mx: 2 }} />
          </>
        ) : (
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            Break
          </DialogTitle>
        )}
        {!isBreak ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          ''
        )}
        <DialogContent dividers>
          {isBreak ? (
            <div>
              {customTime !== 0 ? (
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  Currently You’re on {customTime < 60 ? `${customTime} Minutes` : `${customTime / 60} Hour`} break.
                </Typography>
              ) : (
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  Currently You’re on Lunch break
                </Typography>
              )}
              {customTime !== 0 ? (
                <Stack direction="row" spacing={4} sx={{ mb: 4, mt: 3 }}>
                  <Typography variant="subtitle1">
                    {' '}
                    StartTime :{' '}
                    <span style={{ fontWeight: '400' }}>
                      {new Date(startTime).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      })}
                    </span>
                  </Typography>
                  <Typography variant="subtitle1">
                    EndTime:{' '}
                    <span style={{ fontWeight: '400' }}>
                      {new Date(new Date(startTime).getTime() + customTime * 60 * 1000).toLocaleTimeString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true,
                      })}
                    </span>
                  </Typography>{' '}
                </Stack>
              ) : null}
            </div>
          ) : (
            <>
              <Stack direction="row" spacing={2}>
                <InputMask maskChar={null} mask="99:99" onChange={handleCustomTimeChange}>
                  {(inputProps) => (
                    <TextField
                      {...inputProps}
                      fullWidth
                      error={!!error}
                      helperText={error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="body2">HH:MM</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </InputMask>
              </Stack>

              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                Quick Break
              </Typography>

              <Stack direction="row" spacing={2} sx={{ mb: 3 }}>
                <Button
                  disabled={customTime !== '00:00'}
                  onClick={() => handleTimeChange(5)}
                  variant="outlined"
                  className={active && defaultBreak === 5 ? 'break_active_btn' : ''}
                >
                  5 min Break
                </Button>

                <Button
                  disabled={customTime !== '00:00'}
                  onClick={() => handleTimeChange(10)}
                  variant="outlined"
                  className={active && defaultBreak === 10 ? 'break_active_btn' : ''}
                >
                  10 min Break
                </Button>

                <Button
                  disabled={customTime !== '00:00'}
                  onClick={() => handleTimeChange(15)}
                  variant="outlined"
                  className={active && defaultBreak === 15 ? 'break_active_btn' : ''}
                >
                  15 min Break
                </Button>

                <Button
                  disabled={customTime !== '00:00'}
                  onClick={() => handleTimeChange(0)}
                  variant="outlined"
                  className={active && defaultBreak === 0 ? 'break_active_btn' : ''}
                >
                  Lunch Break
                </Button>
              </Stack>

              <Typography variant="h6" sx={{ mb: 2 }}>
                Comment
              </Typography>

              <TextField
                label="Type your message"
                multiline
                maxRows={3}
                variant="outlined"
                value={message}
                onChange={handleInputChange}
                sx={{ width: '100%' }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions className="break_btn-section">
          {isBreak ? (
            <Button variant="contained" onClick={handleBackTOWork} sx={{ textTransform: 'inherit' }}>
              {loading ? 'Loading...' : 'Back to Work'}
            </Button>
          ) : (
            // <Button onClick={handleBreak} variant="contained">
            //   {loading ? 'Loading...' : 'Save'}
            <Button
              onClick={handleBreak}
              variant="contained"
              disabled={customTime === '00:00' && defaultBreak === ''} // Disable if no time is set
              sx={{
                backgroundColor: breakstart ? 'green' : 'green',
                '&:hover': {
                  backgroundColor: breakstart ? 'green' : 'green',
                },
              }}
            >
              {loading ? 'Loading...' : 'Start'}
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
export default BreakComponent;
